import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import Admin from 'Pages/Admin';
import { customTheme } from 'Theme';
import NpxChatContainer from './Components/Chat/Container';

const AdminPage = () => {
  return (
    <ConfigProvider theme={customTheme}>
      <StyleProvider hashPriority="high">
        {/* Các page liên quan đến màn hình phía admin */}
        <Admin />
        <NpxChatContainer />
      </StyleProvider>
    </ConfigProvider>
  );
};

export default AdminPage;
