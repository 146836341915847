export const envConst = {
  // Firebase
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // Youtube
  youtubeApikey1: process.env.REACT_APP_YOUTUBE_API_KEY_1,
  youtubeApikey2: process.env.REACT_APP_YOUTUBE_API_KEY_2,
  youtubeApikey3: process.env.REACT_APP_YOUTUBE_API_KEY_3,
  youtubeApikey4: process.env.REACT_APP_YOUTUBE_API_KEY_4,
  // url api
  chatApi: process.env.REACT_APP_CHAT_API,
};
export const arrBg = [
  'https://images.pexels.com/photos/4601192/pexels-photo-4601192.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
  'https://images.pexels.com/photos/1485894/pexels-photo-1485894.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
  'https://images.pexels.com/photos/176851/pexels-photo-176851.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
  'https://images.pexels.com/photos/1233414/pexels-photo-1233414.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
  'https://images.pexels.com/photos/1172675/pexels-photo-1172675.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
  'https://images.pexels.com/photos/1097456/pexels-photo-1097456.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
];
