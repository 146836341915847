import { CommentOutlined } from '@ant-design/icons';
import { FloatButton, Popover } from 'antd';
import NpxChatFirestore from './Firestore';

const NpxChatContainer = () => {
  return (
    <>
      <Popover content={<NpxChatFirestore />} title="Vừa nghe nhạc vừa chém gió" trigger="click" placement="topLeft">
        <FloatButton shape="circle" type="primary" icon={<CommentOutlined />} />
      </Popover>
    </>
  );
};

export default NpxChatContainer;
