import { SearchOutlined } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import React from 'react';
interface Props extends InputProps {}
const InputSearch = ({ ...props }: Props) => {
  return (
    <>
      <Input
        size="large"
        className="!min-w-[70vw] !border-transparent !bg-[#242424] !px-4"
        prefix={<SearchOutlined className="text-base" />}
        placeholder="Nhập bài hát muốn nghe"
        value={props.value}
        {...props}
      />
    </>
  );
};

export default InputSearch;
