/*
 * generates random colors from  https://ant.design/docs/spec/colors. <color-4> used.
 */
export const getRandomColorFromString = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;

  return colors[hash];
};
const colors = [
  '#ff9c6e',
  '#ff7875',
  '#ffc069',
  '#ffd666',
  '#fadb14',
  '#95de64',
  '#5cdbd3',
  '#69c0ff',
  '#85a5ff',
  '#b37feb',
  '#ff85c0',
  '#d4b106',
  '#8c8c8c',
  '#f5222d',
  '#fa541c',
  '#fa8c16',
  '#faad14',
  '#fadb14',
  '#a0d911',
  '#52c41a',
  '#13c2c2',
  '#1890ff',
  '#2f54eb',
  '#722ed1',
  '#eb2f96',
  '#faad14',
  '#fa541c',
  '#fa8c16',
  '#fadb14',
  '#a0d911',
  '#13c2c2',
  '#1890ff',
  '#2f54eb',
  '#722ed1',
  '#eb2f96',
];
