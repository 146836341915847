import { Layout } from 'antd';
const {  Footer,  } = Layout;

const FooterUI = () => {
  return (
    <div><Footer style={{ textAlign: 'center' }} className="relative !bg-transparent">
    NPX MUSIC ©2023 Create by NPX
  </Footer></div>
  )
}

export default FooterUI