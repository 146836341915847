import { ThemeConfig } from 'antd';

export const customTheme: ThemeConfig = {
  token: {
    // Seed Token
    colorPrimary: '#1ed760',
    colorText: '#ffffff',
    borderRadius: 4,
    // Alias Token
    colorBgContainer: '#fff',
    colorBgLayout: '#000',
    colorBgBase: '#212121',
    colorBgContainerDisabled: '#fff',
  },
  components: {
    Input: {
      colorBorder: '#ffffff',
      colorTextPlaceholder: '#757575',
      borderRadius: 100,
      activeShadow: '0 0 0 2px #fff',
    },
    Button: {
      borderRadius: 20,
      defaultBg: '#f6f6f6',
      colorBgBase: '#f6f6f6',
      defaultHoverColor: '#000000',
      defaultHoverBorderColor: '#000000',
      fontWeight: 700,
      colorText: '#000000',
      colorTextBase: '#f6f6f6',
    },
  },
};
