// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { envConst } from 'Utils/Constanst';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseMusicConfig = {
  apiKey: envConst.firebaseApiKey,
  authDomain: 'npx-music.firebaseapp.com',
  projectId: 'npx-music',
  storageBucket: 'npx-music.appspot.com',
  messagingSenderId: '104311437458',
  appId: '1:104311437458:web:92f67c60d367f5190e8e4b',
  measurementId: 'G-W51LECLR1N',
  databaseURL: 'https://npx-music-default-rtdb.asia-southeast1.firebasedatabase.app/',
};

// Initialize Firebase
export const appMusic = initializeApp(firebaseMusicConfig);
export const dbMusic = getDatabase(appMusic);
export const dbChat = getFirestore(appMusic);
export const analytics = getAnalytics(appMusic);
