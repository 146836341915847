import { Button, Divider } from 'antd';
import { Video } from 'Pages/User';
import React from 'react';

type Props = {
  recentListVideo: Video[];
  handleAddVideo: (item: any) => void;
};
const RecentListVideo = ({ recentListVideo, handleAddVideo }: Props) => {
  return (
    <React.Fragment>
      {/* LIST VIDEO SEARCH */}
      <div className="w-full md:h-[65vh] md:overflow-y-auto">
        {recentListVideo.map((item: Video, index: number) => {
          return (
            <React.Fragment key={index}>
              <div key={index} className="flex items-center gap-x-3 px-3 hover:bg-gray-800">
                <div className="flex items-center">
                  <img width={100} src={item.thumbnail} alt={item.thumbnail} />
                </div>
                <div className="flex w-full flex-col">
                  <h5 className="text-[13px]">{item.title}</h5>
                  {/* <p className="text-[12px]">{item.channelTitle}</p> */}
                </div>
                <div>
                  <Button onClick={() => handleAddVideo(item)}>Thêm</Button>
                </div>
              </div>
              <Divider className="!my-2" />
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default RecentListVideo;
