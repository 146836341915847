/* eslint-disable react-hooks/exhaustive-deps */
import { PauseCircleOutlined, PlayCircleOutlined, StepForwardOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Button, Dropdown, Empty, Layout, List, Space, Spin, Tabs, Tag, Tooltip, message, theme } from 'antd';
import axios from 'axios';
import { dbMusic } from 'Config/firebase.config';
import { onValue, ref, set } from 'firebase/database';
import { SearchResults, VideoItems } from 'Model/VideoResponse';
import FooterUI from 'Pages/Components/Footer';
import InputSearch from 'Pages/Components/InputSearch';
import ListVideoSearch from 'Pages/Components/ListVideo/ListVideoSearch';
import RecentListVideo from 'Pages/Components/ListVideo/RecentListVideo';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { CiMenuKebab } from 'react-icons/ci';
import { FaRegTrashAlt } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import { arrBg } from 'Utils/Constanst';
import { YOUTUBE_API_KEY } from 'Utils/Helpers';
import { GoMoveToTop } from 'react-icons/go';
import ListPlayer from 'Pages/Components/ListVideo/ListPlayer';
import { useLocation } from 'react-router-dom';

export interface Video {
  videoId?: string;
  url: string;
  title: string;
  thumbnail?: string;
  created: number;
  user: string;
  isPaused: boolean;
}
const Admin = () => {
  const location = useLocation();

  // Kiểm tra xem đường dẫn có chứa '/npx-admin' hay không
  const isAdmin = location.pathname.includes('/npx-admin');

  console.log({ isAdmin });
  const [loading, setLoading] = useState(false);

  const [listVideoPlay, setListVideoPlay] = useState<Video[]>([]);
  const [currentID, setCurrentID] = useState<string>('');
  const [currentVD, setCurrentVD] = useState<Video | undefined>();

  const playerRef = useRef<ReactPlayer>(null);

  const [txtKeyword, setTxtKeyword] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResults>();
  const [listVideoSearch, setListVideoSearch] = useState<VideoItems[]>([]);
  const [recentList, setRecentList] = useState<Video[]>([]);
  const [activeKey, setActiveKey] = useState('1');

  const videosRef = ref(dbMusic, 'videos');
  const currentVideoRef = ref(dbMusic, 'current');
  const recentListVideoRef = ref(dbMusic, 'recentList');

  // Hàm xử lý tìm kiếm bài hát
  const handleSearch = async (data: { q?: string; pageToken?: string }) => {
    try {
      setLoading(true);
      setActiveKey('0');
      await axios
        .get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            key: YOUTUBE_API_KEY[Math.floor(Math.random() * YOUTUBE_API_KEY.length)],
            part: 'snippet',
            q: data.q,
            type: 'video',
            maxResults: 20,
            pageToken: data.pageToken,
          },
        })
        .then(async (res) => {
          setSearchResults(res.data as SearchResults);
          setListVideoSearch((prev) => [...prev, ...res.data.items]);
        })
        .catch((err) => {
          message.error('Lỗi rồi, thử lại xem sao');
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error searching for videos:', error);
    }
  };

  // Hàm xử lý phát video hiện tại
  const playCurrent = () => {
    if (!currentID) {
      if (listVideoPlay && listVideoPlay.length > 0) {
        set(currentVideoRef, listVideoPlay[0]);
        // Kiểm tra nếu đối tượng đã tồn tại trong mảng
        const data = recentList ?? [];
        const exists = data.some((item) => item.videoId === listVideoPlay[0]?.videoId);

        // Nếu đối tượng không tồn tại, thêm đối tượng mới vào mảng
        if (!exists) {
          data.push(listVideoPlay[0]);
        }
        // Lưu lại video vào list gần đây
        set(recentListVideoRef, data);
      }
    }
  };

  // Hàm xử lý thêm video
  const handleAddVideo = async (item: Video) => {
    const newItem: Video = {
      videoId: item.videoId,
      url: 'https://www.youtube.com/watch?v=' + item.videoId,
      title: item.title,
      thumbnail: item.thumbnail,
      created: new Date().getTime(),
      user: 'Admin',
      isPaused: false,
    };

    if (listVideoPlay.findIndex((e) => e.videoId === newItem.videoId) > -1) {
      return message.warning('Bài hát này đã có trong danh sách đang phát');
    }

    listVideoPlay.push(newItem);
    set(
      videosRef,
      listVideoPlay?.filter((e) => e && e.url),
    ).then(() => {
      message.success('Thêm bài hát thành công nha <3');
    });
  };

  // Hàm xử lý qua video
  const handleNextVideo = () => {
    if (listVideoPlay.length > 1) {
      set(currentVideoRef, listVideoPlay?.filter((e) => e && e.videoId !== currentID)[0]);
      set(
        videosRef,
        listVideoPlay?.filter((e) => e && e.videoId !== currentID),
      );
      // Kiểm tra nếu đối tượng đã tồn tại trong mảng
      const dataRecent = recentList ?? [];
      const exists = dataRecent?.some((item) => item.videoId === listVideoPlay[0]?.videoId);

      // Nếu đối tượng không tồn tại, thêm đối tượng mới vào mảng
      if (!exists) {
        dataRecent?.push(listVideoPlay[0]);
      }
      // Lưu lại video vào list gần đây
      set(recentListVideoRef, dataRecent);
    } else {
      message.warning('Không được next bài cuối cùng');
    }
  };

  const {
    token: { colorBgLayout },
  } = theme.useToken();

  const bgRandom = useMemo(() => {
    return arrBg[Math.floor(Math.random() * arrBg.length)];
  }, []);

  // Xử lý tải thêm video khi cùng 1 từ khóa tìm kiếm
  const handleLoadMoreSearch = () => {
    handleSearch({ q: txtKeyword, pageToken: searchResults?.nextPageToken });
  };

  useEffect(() => {
    playCurrent();

    // Khi list trên db thay đổi thì cũng set list ở dưới như vậy
    onValue(videosRef, (snapshot) => {
      const data: Video[] = snapshot.val();
      setListVideoPlay(data || []);

      playCurrent();
    });
    // Khi current trên db thay đổi thì cũng set current ở dưới như vậy
    onValue(currentVideoRef, (snapshot) => {
      const data: Video = snapshot.val();
      setCurrentID(data?.videoId || '');
      setCurrentVD(data);
      playCurrent();
    });

    onValue(recentListVideoRef, (snapshot) => {
      const data: Video[] = snapshot.val();
      setRecentList(data.reverse() || []);
    });
  }, []);

  return (
    <>
      <div
        className="custom__ui-blur"
        style={{
          background: `url(${bgRandom}) no-repeat center center fixed`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          filter: 'blur(13px)',
          position: 'absolute',
          height: '100%',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
        }}
      ></div>
      <Layout
        className="!min-h-screen"
        style={{
          background: colorBgLayout,
        }}
      >
        <header className="flex justify-center gap-x-3 p-4">
          <div>
            <Space.Compact size="large">
              <InputSearch
                value={txtKeyword}
                onChange={(e) => setTxtKeyword(e.target.value)}
                onPressEnter={() => {
                  setListVideoSearch([]);
                  handleSearch({ q: txtKeyword });
                }}
                // disabled={!currentUser ? true : false}
              />
              <Button
                size="large"
                onClick={() => {
                  setListVideoSearch([]);
                  handleSearch({ q: txtKeyword });
                }}
              >
                Tìm
              </Button>
            </Space.Compact>
          </div>
        </header>
        <div className="relative p-2">
          {/* List music and list search */}
          <div className="w-full grid-cols-2 gap-3 md:grid">
            {/* PLAYING VIDEO */}
            <ReactPlayer
              ref={playerRef}
              url={`https://www.youtube.com/watch?v=${currentID}`}
              controls
              playing
              width={'100%'}
              height={500}
              onEnded={handleNextVideo}
              pip
            />

            {/* LIST VIDEO PLAYER */}
            <div className="w-full">
              <Tabs
                size="small"
                tabBarStyle={{
                  fontWeight: 'bold',
                }}
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={(activeKey) => setActiveKey(activeKey)}
                items={[
                  {
                    key: '0',
                    label: 'Danh sách tìm kiếm',
                    children: (
                      <>
                        <h2 className="px-3 text-xl !text-white">
                          {listVideoSearch.length > 0 ? 'Kết quả tìm kiếm' : 'Hãy tìm kiếm bài hát của bạn'}
                        </h2>
                        {listVideoSearch.length > 0 ? (
                          <>
                            <Spin spinning={loading}>
                              <ListVideoSearch searchResults={listVideoSearch ?? []} handleAddVideo={handleAddVideo} />
                            </Spin>
                            <div className="mt-3 flex justify-center">
                              <Button
                                type="text"
                                icon={<AiOutlineCloudDownload />}
                                className="!text-white"
                                onClick={handleLoadMoreSearch}
                                loading={loading}
                              >
                                Xem thêm kết quả tìm kiếm
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="flex justify-start">
                            <Empty
                              description={<span className="text-gray-300">Không tìm thấy bài nào hết</span>}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          </div>
                        )}
                      </>
                    ),
                  },
                  {
                    key: '1',
                    label: 'Danh sách phát',
                    children: (
                      <>
                        <ListPlayer
                          loading={loading}
                          videoIds={listVideoPlay}
                          currentVD={currentVD}
                          isAdmin={isAdmin}
                        />
                      </>
                    ),
                  },
                  {
                    key: '2',
                    label: 'Danh sách nghe gần đây',
                    children: (
                      <Spin spinning={loading}>
                        <RecentListVideo recentListVideo={recentList} handleAddVideo={handleAddVideo} />
                        {/* <div className="mt-3 flex justify-center">
                          <Button
                            type="text"
                            icon={<AiOutlineCloudDownload />}
                            className="!text-white"
                            onClick={handleLoadMoreListCurrent}
                            loading={loading}
                          >
                            Tải thêm
                          </Button>
                        </div> */}
                      </Spin>
                    ),
                  },
                ]}
                tabBarExtraContent={
                  <Button icon={<StepForwardOutlined />} onClick={handleNextVideo}>
                    Qua bài
                  </Button>
                }
              />
            </div>
          </div>
        </div>
        <FooterUI />
      </Layout>
    </>
  );
};

export default Admin;
