import { Button, Divider } from 'antd';
import { VideoItems } from 'Model/VideoResponse';
import React from 'react';

type Props = {
  searchResults: VideoItems[];
  handleAddVideo: (item: any) => void;
};
const ListVideoSearch = ({ searchResults, handleAddVideo }: Props) => {
  return (
    <React.Fragment>
      {/* LIST VIDEO SEARCH */}
      <div className="w-full md:h-[60vh] md:overflow-y-auto">
        {searchResults.map((item: VideoItems, index: number) => {
          return (
            <React.Fragment key={index}>
              <div key={index} className="flex items-center gap-x-3 px-3 hover:bg-gray-800">
                <div className="flex items-center">
                  <img
                    width={100}
                    src={item.snippet.thumbnails.default.url}
                    alt={item.snippet.thumbnails.default.url}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <h5 className="text-[13px]">{item.snippet.title}</h5>
                  <p className="text-[12px]">{item.snippet.channelTitle}</p>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      const newItem = {
                        videoId: item.id.videoId,
                        url: 'https://www.youtube.com/watch?v=' + item.id.videoId,
                        title: item.snippet.title,
                        thumbnail: item.snippet.thumbnails.high.url,
                      };
                      handleAddVideo(newItem);
                    }}
                  >
                    Thêm
                  </Button>
                </div>
              </div>
              <Divider className="!my-2" />
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ListVideoSearch;
