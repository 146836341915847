import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, onSnapshot, query, orderBy, Timestamp } from 'firebase/firestore';
import { dbChat } from 'Config/firebase.config';
import { getUserSession } from 'Utils/Localstorage';
import { Button, Input, Space } from 'antd';
import { GrSend } from 'react-icons/gr';
import { getRandomColorFromString } from 'Utils/getRandomColor';
import dayjs from 'dayjs';
interface Message {
  id: string;
  text: string;
  createdAt: string;
  createdByName: string;
}
const NpxChatFirestore = () => {
  const messagesColl = collection(dbChat, 'messages');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const q = query(messagesColl, orderBy('createdAt'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as Message[];
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, []);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    await addDoc(messagesColl, {
      text: newMessage,
      createdAt: Date.now(),
      createdByName: getUserSession()?.name || 'Admin',
    });

    setNewMessage('');
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="!flex h-[70vh] w-[70vw] flex-col justify-between text-white md:h-[60vh] md:w-[300px]">
      <div className="overflow-auto p-1 pb-3">
        {messages.map((msg, index) => (
          <div key={index} className="border-b-2 border-b-slate-600 py-1">
            <div className="flex justify-between">
              <span
                className="rounded-full px-2 py-0.5 text-[12px]"
                style={{
                  background: getRandomColorFromString(msg.createdByName),
                }}
              >
                {msg.createdByName}
              </span>
              <span className="text-[10px] italic">
                {msg.createdAt ? dayjs(msg.createdAt).format('YYYY/MM/DD HH:mm:ss') : '-'}
              </span>
            </div>
            <p className="break-words">{msg.text}</p>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <Space.Compact className="w-full">
        <Input
          className="!text-black"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onPressEnter={handleSendMessage}
        />
        <Button onClick={handleSendMessage}>
          <GrSend />
        </Button>
      </Space.Compact>
    </div>
  );
};

export default NpxChatFirestore;
