import { PauseCircleOutlined, PlayCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Video } from 'Pages/User';
import { Avatar, Button, Dropdown, List, message, Space, Tag, Tooltip } from 'antd';
import { CiMenuKebab } from 'react-icons/ci';
import type { MenuProps } from 'antd';
import { GoMoveToTop } from 'react-icons/go';
import { onValue, ref, set } from 'firebase/database';
import { FaRegTrashAlt } from 'react-icons/fa';
import { dbMusic } from 'Config/firebase.config';
import { useLocation } from 'react-router-dom';

type Props = {
  loading: boolean;
  videoIds: Video[];
  currentVD: Video | undefined;
  isAdmin?: boolean;
};
const ListPlayer = ({ loading, videoIds, currentVD, isAdmin }: Props) => {
  const videosRef = ref(dbMusic, 'videos');

  // List menu item in player
  const menuListPlayer = (video: Video): MenuProps => {
    const indexItem = videoIds.findIndex((item) => item.videoId === video.videoId);
    const isSong2 = indexItem === 1;
    return {
      items: [
        {
          key: 'moveTop',
          label: <div>Ưu tiên</div>,
          icon: <GoMoveToTop />,
          disabled: !!isSong2,
          onClick: (info) => {
            try {
              // Tìm index của object trong mảng
              const index = indexItem;

              if (index !== -1 && index > 0) {
                // Lấy object ra khỏi mảng
                const [selectedVideo] = videoIds.splice(index, 1);

                // Chèn object vào vị trí thứ 1 (sau bài đầu tiên đang phát)
                videoIds.splice(1, 0, selectedVideo);

                // Cập nhật danh sách video
                set(videosRef, videoIds);

                message.success('Đã chuyển lên sau bài đang phát!');
              } else {
                message.warning('Không thể chuyển lên sau bài đang phát!');
              }
            } catch (error) {
              message.warning('Lỗi gì rồi, F5 thử lại nhau!');
              console.log(error);
            }
          },
        },
        {
          key: 'delete',
          label: <div>Dở òm, xóa luôn</div>,
          danger: true,
          icon: <FaRegTrashAlt />,
          onClick: (info) => {
            try {
              const newListVideo = videoIds.filter((item) => item.videoId !== video.videoId);
              set(videosRef, newListVideo);
              message.success('Xóa thành công nha!');
            } catch (error) {
              message.warning('Xóa chưa được, F5 xóa lại thử!');
              console.log(error);
            }
          },
        },
        // Thêm các mục khác nếu cần
      ],
    };
  };

  return (
    <List
      className="w-full md:h-[65vh] md:overflow-y-auto [&_.ant-list-item-extra]:overflow-hidden [&_.ant-list-item-extra]:rounded-sm"
      loading={loading}
      dataSource={videoIds}
      itemLayout="vertical"
      size="small"
      renderItem={(item, index) => {
        const isPlaying = currentVD?.videoId === item.videoId;

        return (
          <List.Item
            key={index}
            extra={
              <Space>
                <div className="w-[80px]">
                  <img alt={item.title} src={item.thumbnail} />
                </div>
                {isAdmin && (
                  <Dropdown
                    menu={menuListPlayer(item)}
                    trigger={['click']}
                    className={isPlaying ? 'invisible' : 'visible'}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Button type="text" shape="circle" iconPosition="start" icon={<CiMenuKebab color="#fff" />} />
                    </a>
                  </Dropdown>
                )}
              </Space>
            }
          >
            <List.Item.Meta
              style={{
                marginBottom: 0,
              }}
              avatar={<Avatar size={'small'} className="!bg-gray-400" shape="circle" icon={index + 1} />}
              title={
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: isPlaying ? '#e84118' : '#fff',
                  }}
                  className="!text-[13px]"
                >
                  {item.title}
                </a>
              }
            />
            <div className="flex gap-x-1">
              <Tooltip title="Người yêu cầu">
                <Tag className="!rounded-full" icon={<UserOutlined />} color={isPlaying ? 'red' : 'gray'}>
                  {item.user ?? 'Anonymous'}
                </Tag>
              </Tooltip>
              <Tooltip title="Trạng thái">
                <Tag
                  className="!rounded-full"
                  icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                  color={isPlaying ? 'red' : 'gray'}
                >
                  {isPlaying ? <>Đang phát</> : <>Chờ phát</>}
                </Tag>
              </Tooltip>
            </div>
          </List.Item>
        );
      }}
    />
  );
};

export default ListPlayer;
