/* eslint-disable react-hooks/exhaustive-deps */
import { dbMusic } from 'Config/firebase.config';
import { onValue, ref, set } from 'firebase/database';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import YouTube from 'react-youtube';

import { FastForwardOutlined, GoogleOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { LoginResponse } from 'Model/Users';
import { SearchResults, VideoItems } from 'Model/VideoResponse';
import FooterUI from 'Pages/Components/Footer';
import InputSearch from 'Pages/Components/InputSearch';
import ListPlayer from 'Pages/Components/ListVideo/ListPlayer';
import ListVideoSearch from 'Pages/Components/ListVideo/ListVideoSearch';
import RecentListVideo from 'Pages/Components/ListVideo/RecentListVideo';
import { arrBg } from 'Utils/Constanst';
import { YOUTUBE_API_KEY } from 'Utils/Helpers';
import { getUserSession } from 'Utils/Localstorage';
import { getRandomColorFromString } from 'Utils/getRandomColor';
import type { MenuProps } from 'antd';
import { Avatar, Button, Dropdown, Empty, Layout, Space, Spin, Tabs, Typography, message, theme } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { AiOutlineCloudDownload, AiOutlineUser } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const items = [
  {
    key: '1',
    label: 'Yêu cầu nhạc',
    icon: React.createElement(UsergroupAddOutlined),
  },
  {
    key: '2',
    label: 'Nghe nhạc riêng',
    icon: React.createElement(UserOutlined),
  },
];

const provider = new GoogleAuthProvider();
const auth = getAuth();

export interface Video {
  videoId?: string;
  url: string;
  title: string;
  thumbnail?: string;
  created: number;
  user: string;
  isPaused: boolean;
}

const UserMusicRequest = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingPlayer, setLoadingPlayer] = useState(false);

  const [videoIds, setVideoIds] = useState<Video[]>([]);
  const [currentVD, setCurrentVD] = useState<Video | undefined>();
  const playerRef = useRef<YouTube | null>(null);

  const [currentUser, setCurrentUser] = useState<LoginResponse | undefined>();

  const [txtKeyword, setTxtKeyword] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResults>();
  const [listVideoSearch, setListVideoSearch] = useState<VideoItems[]>([]);
  const [recentList, setRecentList] = useState<Video[]>([]);
  const [activeKey, setActiveKey] = useState('0');

  const videos = ref(dbMusic, 'videos');
  const currentVideo = ref(dbMusic, 'current');
  const recentListVideo = ref(dbMusic, 'recentList');

  const checkUserLogined = () => {
    const userInfo: LoginResponse = getUserSession();
    if (userInfo) {
      setCurrentUser(userInfo);
    }
    setLoading(false);
    setLoadingPlayer(false);
  };

  useEffect(() => {
    checkUserLogined();
    // List trên db thay đổi thì set list tương tự
    onValue(videos, (snapshot) => {
      const data: Video[] = snapshot.val();
      setVideoIds(data?.filter((e) => e && e.url) || []);
    });
    // Current trên db thay đổi thì set current tương tự
    onValue(currentVideo, (snapshot) => {
      const data: Video = snapshot.val();
      setCurrentVD(data);
    });
    // List video nghe gần đây
    onValue(recentListVideo, (snapshot) => {
      const data: Video[] = snapshot.val();
      setRecentList(data?.reverse() ?? []);
    });
  }, []);

  // Hàm xử lý tìm kiếm bài hát
  const handleSearch = async (data: { q?: string; pageToken?: string }) => {
    try {
      setLoading(true);
      setActiveKey('0');
      await axios
        .get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            key: YOUTUBE_API_KEY[Math.floor(Math.random() * YOUTUBE_API_KEY.length)],
            part: 'snippet',
            q: data.q,
            type: 'video',
            maxResults: 20,
            pageToken: data.pageToken,
          },
        })
        .then(async (res) => {
          setSearchResults(res.data as SearchResults);
          setListVideoSearch((prev) => [...prev, ...res.data.items]);
        })
        .catch((err) => {
          message.error('Lỗi rồi, thử lại xem sao');
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error searching for videos:', error);
    }
  };

  const handleAddVideo = async (item: Video) => {
    setLoadingPlayer(true);
    const newItem: Video = {
      videoId: item.videoId,
      url: 'https://www.youtube.com/watch?v=' + item.videoId,
      title: item.title,
      thumbnail: item.thumbnail,
      created: new Date().getTime(),
      user: currentUser?.name ?? 'Anonymos',
      isPaused: false,
    };

    if (videoIds.findIndex((e) => e.videoId === newItem.videoId) > -1) {
      setLoadingPlayer(false);
      return message.warning('Bài hát này đã có trong danh sách đang phát');
    }

    videoIds.push(newItem);
    set(
      videos,
      videoIds?.filter((e) => e && e.url),
    )
      .then(() => {
        message.success('Thêm bài hát thành công nha <3');
      })
      .finally(() => {
        setLoadingPlayer(false);
      });
  };

  // Xử lý bỏ qua bài hát
  const handleNextVideo = () => {
    if (currentVD?.user === 'Admin') {
      message.warning('Thông cảm. Không cho next bài của Admin');
      return;
    }
    if (videoIds.length > 0) {
      const newList = videoIds?.filter((e, index) => index !== 0);
      set(ref(dbMusic, 'current'), newList.length > 0 ? newList[0] : null);
      set(ref(dbMusic, 'videos'), newList || []);
    } else {
      playerRef.current?.internalPlayer.playVideo();
    }
  };

  // Xử lý login với google
  const handleLoginGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result: any) => {
        const user = {
          _id: result.user.uid,
          access_token: result.user.accessToken,
          name: result.user.displayName,
          email: result.user.email,
          role: 'USER',
          photoURL: result.user.photoURL,
        };

        setUserToLocal(user as LoginResponse);
        setCurrentUser(user as LoginResponse);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const login = async (username: string, password: string) => {
    const config = {
      method: 'post',
      url: 'http://localhost:8000/auth/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        username,
        password,
      },
    };
    const response: AxiosResponse = (await axios(config)).data;
    return response.data;
  };

  const setUserToLocal = (response: LoginResponse) => {
    localStorage.setItem('user_session', JSON.stringify(response));
  };

  const handleLoginWithoutAccount = async () => {
    const userAnony = [
      {
        email: 'chuchohaycuoi@npxofficial.com',
        password: '123456',
      },
      {
        email: 'chumeohaykhoc@npxofficial.com',
        password: '123456',
      },
    ];

    const randomAcc = Math.floor(Math.random() * userAnony.length);

    try {
      const response: LoginResponse = await login(userAnony[randomAcc].email, userAnony[randomAcc].password);
      setUserToLocal(response as LoginResponse);
      setCurrentUser(response);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG, colorBgLayout, colorBgBase },
  } = theme.useToken();

  const bgRandom = useMemo(() => {
    return arrBg[Math.floor(Math.random() * arrBg.length)];
  }, []);

  // Xử lý tải thêm video khi cùng 1 từ khóa tìm kiếm
  const handleLoadMore = () => {
    handleSearch({ q: txtKeyword, pageToken: searchResults?.nextPageToken });
  };

  const handleLogout = () => {
    localStorage.removeItem('user_session');
    message.success('Đăng xuất thành công nhé');
    window.location.reload();
  };

  const settingItems: MenuProps['items'] = [
    {
      label: <div onClick={handleLogout}>Đăng xuất</div>,
      key: '0',
    },
  ];

  return (
    <>
      <div
        className="custom__ui-blur"
        style={{
          background: `url(${bgRandom}) no-repeat center center fixed`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          filter: 'blur(13px)',
          position: 'absolute',
          height: '100%',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
        }}
      ></div>
      <Layout
        className="custom__ui-user !min-h-screen"
        style={{
          background: colorBgLayout,
        }}
      >
        <Content className="p-2">
          <div
            className="min-h-full"
            style={{
              borderRadius: borderRadiusLG,
            }}
          >
            <header className="flex items-center justify-center gap-x-3 p-4">
              <Space.Compact size="large">
                <InputSearch
                  value={txtKeyword}
                  onChange={(e) => setTxtKeyword(e.target.value)}
                  onPressEnter={() => {
                    setListVideoSearch([]);
                    handleSearch({ q: txtKeyword });
                  }}
                  disabled={!currentUser ? true : false}
                />
                <Button
                  size="large"
                  onClick={() => {
                    setListVideoSearch([]);
                    handleSearch({ q: txtKeyword });
                  }}
                >
                  Tìm
                </Button>
              </Space.Compact>
              <div hidden={!!!currentUser}>
                <div className="flex cursor-pointer items-center gap-x-2">
                  <Dropdown menu={{ items: settingItems }} trigger={['click']}>
                    <a>
                      <Space>
                        <Avatar
                          style={{ background: getRandomColorFromString(getUserSession()?.name ?? 'A') }}
                          icon={getUserSession()?.photoURL ? undefined : <AiOutlineUser />}
                          src={getUserSession() ? getUserSession()?.photoURL : undefined}
                        >
                          {/* {getNameInitials(getUserSession()?.name ?? 'Ai biết', 1)} */}
                        </Avatar>
                        <span className="relative hidden text-white md:block">{getUserSession()?.name}</span>
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </header>
            {/* List music and list search */}
            <Layout className="text-white">
              <div className="flex w-full flex-col justify-around gap-1 md:flex-row">
                <div className="relative w-full rounded-r-md bg-inherit md:!w-[48%]">
                  {currentUser ? (
                    <>
                      <Tabs
                        size="small"
                        tabBarStyle={{
                          fontWeight: 'bold',
                        }}
                        defaultActiveKey="0"
                        activeKey={activeKey}
                        onChange={(activeKey) => setActiveKey(activeKey)}
                        items={[
                          {
                            key: '0',
                            label: 'Danh sách tìm kiếm',
                            children: (
                              <>
                                <h2 className="px-3 text-xl !text-white">
                                  {listVideoSearch.length > 0 ? 'Kết quả tìm kiếm' : 'Hãy tìm kiếm bài hát của bạn'}
                                </h2>
                                {listVideoSearch.length > 0 ? (
                                  <>
                                    <Spin spinning={loading}>
                                      <ListVideoSearch
                                        searchResults={listVideoSearch ?? []}
                                        handleAddVideo={handleAddVideo}
                                      />
                                    </Spin>
                                    <div className="mt-3 flex justify-center">
                                      <Button
                                        type="text"
                                        icon={<AiOutlineCloudDownload />}
                                        className="!text-white"
                                        onClick={handleLoadMore}
                                        loading={loading}
                                      >
                                        Xem thêm kết quả tìm kiếm
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <div className="flex justify-start">
                                    <Empty
                                      description={<span className="text-gray-300">Không tìm thấy bài nào hết</span>}
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  </div>
                                )}
                              </>
                            ),
                          },
                          {
                            key: '1',
                            label: 'Danh sách nghe gần đây',
                            children: (
                              <>
                                <RecentListVideo recentListVideo={recentList} handleAddVideo={handleAddVideo} />
                              </>
                            ),
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <div className="flex min-h-[300px] w-full flex-col items-center justify-center gap-3 md:h-full">
                      <Button
                        className="w-4/5 text-xs !font-semibold uppercase md:w-1/2"
                        type="primary"
                        onClick={handleLoginGoogle}
                        icon={<GoogleOutlined />}
                      >
                        Đăng nhập bằng Google
                      </Button>
                      {/* <Button
                        htmlType="button"
                        className="w-4/5 text-xs !font-semibold uppercase !text-black md:w-1/2"
                        onClick={handleLoginWithoutAccount}
                        icon={<FaUserSecret />}
                        hidden
                      >
                        Đăng nhập ẩn danh
                      </Button> */}
                    </div>
                  )}
                </div>
                <div className="w-full rounded-r-md bg-inherit p-2 md:w-[48%]">
                  <div className="flex justify-between">
                    <h2 className="relative text-xl !text-white">Danh sách đang phát</h2>
                    {currentUser && (
                      <Button icon={<FastForwardOutlined />} onClick={handleNextVideo}>
                        Qua bài khác
                      </Button>
                    )}
                  </div>

                  <ListPlayer loading={loadingPlayer} videoIds={videoIds} currentVD={currentVD} />
                </div>
              </div>
            </Layout>
          </div>
        </Content>

        <FooterUI />
      </Layout>
    </>
  );
};

export default UserMusicRequest;
